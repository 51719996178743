import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { ConfirmMsgComponent } from 'src/app/components/messages/confirm-msg/confirm-msg.component';
import { NgSocialLinksService } from 'ng-social-links';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FirebaseApp } from '@angular/fire';

@Component({
  selector: 'app-link-dialog',
  templateUrl: './link-dialog.component.html',
  styleUrls: ['./link-dialog.component.scss']
})
export class LinkDialogComponent implements OnInit {

  constructor(
    public app: FirebaseApp,
    public library: FaIconLibrary,
    private socialLinks: NgSocialLinksService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<LinkDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    library.addIconPacks(fas, far, fab);
  }

  emailFlag = false;
  email = new FormControl('', [Validators.required, Validators.email]);

  ngOnInit(): void {}

  onCopyToClipboard(){
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', this.data.sharedLink);
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }

  onDeleteLink(){
    this.dialog.open(ConfirmMsgComponent, {
      width: '30%',
      data: {
        message: 'The recently generated link and all related data will be permanently deleted! Continue?'
      }
    })
    .afterClosed()
    .subscribe(r => {
      if (r.result){
        this.app.database().ref('/shareData').child(this.data.id).remove();
        this.dialogRef.close();
      }
    });
  }

  onShareLink(nt: any){
    const socialLink = this.socialLinks.getShareLink(nt, { url: `${this.data.sharedLink}`, title: 'Diffter - Compare texts online'});
    this.popupwindow(socialLink, 600, 600);
  }

  popupwindow(url, w, h) {
    const left = (screen.width / 2) - (w / 2);
    const top = (screen.height / 2) - (h / 2);
    return window.open(url, 'SocialSharePopup', 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);
  }
}

<div>
    <div class="logo" [routerLink]="['home']">
        <h1>Diffter</h1>
    </div>

    <div class="right_menu">
        <button 
            mat-icon-button 
            *ngIf="false" 
            matTooltip="Main Page"
            [routerLink]="['main']"
            > 
            <mat-icon>house</mat-icon> 
        </button>
        <button 
            mat-icon-button 
            *ngIf="true" 
            matTooltip="Side by side comparison"
            [routerLink]="['diff']"
            > 
            <mat-icon>flip</mat-icon> 
        </button>
        <button 
            mat-icon-button 
            matTooltip="Editor"
            [routerLink]="['editor']"
            > 
            <mat-icon>edit</mat-icon> 
        </button>
        <button mat-icon-button [matMenuTriggerFor]="themeSelector" matTooltip="Change the editor theme"> <mat-icon>style</mat-icon> </button>
        <button mat-icon-button *ngIf="!(authenticationService.userData$ | async)?.email" matTooltip="Sign in" [routerLink]="['login']"> <mat-icon>login</mat-icon> </button>
        <button mat-icon-button *ngIf="false" matTooltip="Admin panel"> <mat-icon>admin_panel_settings</mat-icon> </button>
        <button mat-icon-button *ngIf="(authenticationService.userData$ | async)?.email" [routerLink]="['user/welcome']">
            <mat-icon>person</mat-icon> 
        </button>

        <mat-menu #themeSelector="matMenu">
            <button mat-menu-item (click)="onThemeSelect('vs')">Light(default)</button>
            <button mat-menu-item (click)="onThemeSelect('vs-dark')">Dark</button>
            <button mat-menu-item (click)="onThemeSelect('hc-black')">High Contrast Dark</button>
        </mat-menu>
    </div>
</div>
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InfoMsg } from 'src/app/model/msg';

@Component({
  selector: 'app-info-msg',
  templateUrl: './info-msg.component.html',
  styleUrls: ['./info-msg.component.scss']
})
export class InfoMsgComponent {

  title = '';
  message = '';

  constructor(
    public dialogRef: MatDialogRef<InfoMsgComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InfoMsg
  ) { }
}

import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-forgot-pass-dialog',
  templateUrl: './forgot-pass-dialog.component.html',
  styleUrls: ['./forgot-pass-dialog.component.scss']
})
export class ForgotPassDialogComponent {

  constructor(
    public authenticationService: AuthenticationService,
    public dialogRef: MatDialogRef<ForgotPassDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  myForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  onReset(){
    const { email } = this.myForm.value;
    this.authenticationService.ForgotPass(email);
    this.dialogRef.close({result: true});
  }

  getErrorMsg() {
    const { email } = this.myForm.value;
    return (!email) ? 'You must enter your email' : 'Not a valid email';
  }
}

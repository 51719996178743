import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Guard
import { UserguardGuard } from './services/userguard.guard';

// components
import { MainComponent } from './components/context/main/main.component';
import { MainDiffComponent } from './components/context/main-diff/main-diff.component';
import { NotFoundComponent } from './components/messages/not-found/not-found.component';
import { EditComponent } from './components/context/edit/edit.component';
import { LoginComponent } from './components/login/login.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: MainComponent
  },
  {
    path: 'admin',
    loadChildren: () => import('./components/users/admin/admin.module').then(m => m.AdminModule),
    canLoad: [UserguardGuard],
  },
  {
    path: 'diff',
    component: MainDiffComponent
  },
  {
    path: 'diff/params',
    component: MainDiffComponent
  },
  {
    path: 'editor',
    component: EditComponent
  },
  {
    path: 'editor/params',
    component: EditComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'user',
    loadChildren: () => import('./components/users/user-account/user-account.module').then(m => m.UserAccountModule),
    canActivate: [UserguardGuard]
  },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

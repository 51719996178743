import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'Diffter';
  constructor( private auth: AngularFireAuth ) {}
  ngOnInit(){
    this.auth.signInAnonymously().catch(err => console.log('AUTH ERROR:', err));
  }
}

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router } from '@angular/router';
import { ForgotPassDialogComponent } from './forgot-pass-dialog/forgot-pass-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FirebaseApp } from '@angular/fire';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    pass : new FormControl('', [Validators.required])
 });
  signupForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    pass : new FormControl('', [Validators.required, Validators.minLength(6)]),
    pass2: new FormControl('', [Validators.required])
  });
  get pass2() { return this.signupForm.get('pass2'); }
  hide = true;
  hide2 = true;
  formFlag = true;

  constructor(
    public app: FirebaseApp,
    public authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private router: Router,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {}

  getEmailErrorMessage(email){ return (!email) ? 'You must enter your email' : 'Not a valid email'; }
  getPassErrMsg(data) {
    const { pass } = data;
    return (pass.length < 6 ) ? 'Password should be at least 6 characters' : 'The password cannot be blan';
  }
  getConfirmPassErrMsg(data) {
    const { pass, pass2 } = data;
    if (pass !== pass2){ this.pass2.setErrors([{passwordMismatch: true}]); }
    return ( pass !== pass2) ? 'The Entered passwords do not match' : 'Please confirm your password';
  }

  submit(){
    const { email, pass } = this.loginForm.value;
    this.authenticationService.SignIn(email, pass).then(r => {
      if (r.result) {
        this.snackBar.open(`Welcome back!`, 'OK', { duration: 4000 });
        this.router.navigate(['/user/welcome']);
      }else {
        this.snackBar.open(`Error! ${r.msg}`, 'OK', { duration: 4000 });
      }
    });
  }

  submitSignUp(){
    const { email, pass } = this.signupForm.value;
    this.authenticationService
    .SignUp(email, pass)
    .then(r => {
      if (r.result) {
        this.snackBar.open(`Success! ${r.msg}`, 'OK', { duration: 4000 });
        this.router.navigate(['/user/welcome']);
      }else {
        this.snackBar.open(`Error! ${r.err.message}`, 'OK', { duration: 4000 });
      }
    });
  }

  onForgotPass(){
    const dialogRef = this.dialog.open(ForgotPassDialogComponent, {
      width: '400px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result){
        this.snackBar.open('Your password and further instructions have been sent to your e-mail address.', 'OK', { duration: 4000 });
      }
    });
  }
}

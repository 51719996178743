<div class="diffBody" [ngClass]="options.theme">

    <div class="tools">
        <div>
            <div class="file_container" ngx-dropzone [multiple]="false" [accept]="'text/*'" (change)="onSelect($event, true)">
                <button mat-icon-button>
                    <mat-icon>folder_open</mat-icon>
                </button>
                <p>{{ firstFileMesage }}</p>
            </div>
            <button mat-icon-button *ngIf="authService.getLoggedInStatus() && (originalModel.code.length > 1 || modifiedModel.code.length > 1)" (click)="onAuthSave()" matTooltip="save document"><mat-icon>save</mat-icon></button>
            <button mat-icon-button *ngIf="originalModel.code.length > 1 || modifiedModel.code.length > 1" [matMenuTriggerFor]="shareMenu" matTooltip="share documents">
                <mat-icon>share</mat-icon>
            </button>
            <button mat-icon-button *ngIf="originalModel.code.length > 1" (click)="onEdit(true)" matTooltip="open in editor"> <mat-icon>edit</mat-icon> </button>
            <button mat-icon-button *ngIf="originalModel.code.length > 1" (click)="onSave(true)" matTooltip="download origin document"> <mat-icon>archive</mat-icon> </button>
            <button mat-icon-button *ngIf="originalModel.code.length > 1" (click)="onFindOrigin()" matTooltip="open find widget"> <mat-icon>find_in_page</mat-icon> </button>
            <button mat-icon-button *ngIf="originalModel.code.length > 1" (click)="deleteCode(true)" matTooltip="remove document"> <mat-icon>delete_sweep</mat-icon> </button>
            <mat-menu #shareMenu="matMenu">
                <p class="menu_label" (click)='onTerms()'>Attention. By clicking Generate link, you agree to our terms.</p>
                <button class="menu_button" mat-menu-item (click)="onGenerateLink()">Generate link</button>
            </mat-menu>
        </div>
        <div>
            <div class="file_container" ngx-dropzone [multiple]="false" [accept]="'text/*'" (change)="onSelect($event, false)">
                <button mat-icon-button>
                    <mat-icon>folder_open</mat-icon>
                </button>
                <p>{{ secondFileMesage }}</p>
            </div>
            <button mat-icon-button *ngIf="modifiedModel.code.length > 1" (click)="onEdit(false)" matTooltip="open in editor"> <mat-icon>edit</mat-icon> </button>
            <button mat-icon-button *ngIf="modifiedModel.code.length > 1" (click)="onSave(false)" matTooltip="download modified document"> <mat-icon>archive</mat-icon> </button>
            <button mat-icon-button *ngIf="modifiedModel.code.length > 1" (click)="onFindModified()" matTooltip="open find widget"> <mat-icon>find_in_page</mat-icon> </button>
            <button mat-icon-button *ngIf="modifiedModel.code.length > 1" (click)="deleteCode(false)" matTooltip="remove document"> <mat-icon>delete_sweep</mat-icon> </button>          
        </div>
    </div>
    
    <ngx-monaco-diff-editor [options]="options" [originalModel]="originalModel" [modifiedModel]="modifiedModel" #diffter></ngx-monaco-diff-editor>
    <div class='adsDiff'>
        <iframe 
            src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=13&l=ez&f=ifr&linkID=c7f0f63fef0f94494fdc0b9b1fb8b06d&t=34710-20&tracking_id=34710-20" 
            width="468" height="60" 
            scrolling="no" 
            border="0" 
            marginwidth="0" 
            style="border:none;" 
            frameborder="0">
        </iframe>
    </div>
</div>

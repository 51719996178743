import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class UserguardGuard implements CanActivate, CanLoad {
  constructor(
    private router: Router,
    private authService: AuthenticationService) {
  }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService.getLoggedInStatus()) {
      return true;
    }
    this.router.navigate(['/diff']);
    return false;
  }
  canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.getLoggedInStatus()) {
      return true;
    }
    this.router.navigate(['/diff']);
    return false;
  }
}

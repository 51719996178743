import { Component, OnInit } from '@angular/core';
import { TermsComponent } from './terms/terms.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  onTerms(){
    this.dialog.open(TermsComponent);
  }
}

<mat-form-field appearance="outline" disabled>
    <input matInput [value]='this.data.sharedLink' disabled>
</mat-form-field>

<div class="shareLinks">
    <button mat-icon-button (click)="onCopyToClipboard()" matTooltip="copy link to the clipboard">
        <fa-icon [icon]="['far', 'clipboard']" size="lg"></fa-icon>
    </button>

    <button mat-icon-button matTooltip="send link" (click)="onShareLink('mail')">
        <fa-icon [icon]="['fas', 'envelope']" size="lg"></fa-icon>
    </button>

    <button mat-icon-button matTooltip="share link to facebook" (click)="onShareLink('fb')">
        <fa-icon [icon]="['fab', 'facebook']" size="lg"></fa-icon>
    </button>

    <button mat-icon-button matTooltip="share link to twitter" (click)="onShareLink('tw')">
        <fa-icon [icon]="['fab', 'twitter']" size="lg"></fa-icon>
    </button>

    <button mat-icon-button matTooltip="share link to linkedin" (click)="onShareLink('in')">
        <fa-icon [icon]="['fab', 'linkedin']" size="lg"></fa-icon>
    </button>

    <button mat-icon-button matTooltip="share link to vk" (click)="onShareLink('vk')">
        <fa-icon [icon]="['fab', 'vk']" size="lg"></fa-icon>
    </button>

    <button mat-icon-button matTooltip="share link to telegram" (click)="onShareLink('tg')">
        <fa-icon [icon]="['fab', 'telegram']" size="lg"></fa-icon>
    </button>

    <button mat-icon-button matTooltip="share link to reddit" (click)="onShareLink('re')">
        <fa-icon [icon]="['fab', 'reddit']" size="lg"></fa-icon>
    </button>

    <button mat-icon-button matTooltip="share link to pinterest" (click)="onShareLink('pi')">
        <fa-icon [icon]="['fab', 'pinterest']" size="lg"></fa-icon>
    </button>

    <button mat-icon-button matTooltip="send link to skype" (click)="onShareLink('sk')">
        <fa-icon [icon]="['fab', 'skype']" size="lg"></fa-icon>
    </button>

    <button mat-icon-button matTooltip="delete link" (click)="onDeleteLink()">
        <fa-icon [icon]="['fas', 'trash']" size="lg"></fa-icon>
    </button>

    <button mat-icon-button mat-dialog-close matTooltip="close the dialog">
        <fa-icon [icon]="['fas', 'times']" size="lg"></fa-icon>
    </button>
</div>

<!-- <ng-adsense [height]="108" ></ng-adsense> -->
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { DiffEditorModel } from 'ngx-monaco-editor';
import { AppService } from '../../../services/app.service';
import { Subscriber } from 'rxjs/internal/Subscriber';
import { v4 as uuidv4 } from 'uuid';

import { TermsComponent } from '../../footer/terms/terms.component';
import { LinkDialogComponent } from './components/link-dialog/link-dialog.component';

import { FirebaseApp } from '@angular/fire';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-main-diff',
  templateUrl: './main-diff.component.html',
  styleUrls: ['./main-diff.component.scss']
})
export class MainDiffComponent implements OnInit {

  @ViewChild('diffter') editor: any;
  constructor(
    public app: FirebaseApp,
    private route: ActivatedRoute,
    private router: Router,
    private appService: AppService,
    public dialog: MatDialog,
    public authService: AuthenticationService,
    private snackBar: MatSnackBar
  ) { }

  options = { theme: 'vs' };

  firstFileMesage = '/Drop your file here';
  secondFileMesage = '/Drop your file here';
  appSettingSubscriber = Subscriber.EMPTY;
  routeSubscriber = Subscriber.EMPTY;

  originalModel: DiffEditorModel = {
    code: ' ',
    language: 'text/plain'
  };

  modifiedModel: DiffEditorModel = {
    code: ' ',
    language: 'text/plain'
  };

  fileName = null;
  fileType = null;
  fileName2 = null;
  fileType2 = null;

  docId = null;

  ngOnInit(): void {
    // load page with params
    this.routeSubscriber = this.route.queryParams.subscribe(params => {
      const {id, docId} = params;
      if (id){
        this.app
          .database()
          .ref('/shareData/')
          .child(id)
          .once('value')
          .then((snapshot) => {
            const { text1, text2, fileName, fileName2, fileType, fileType2 } = snapshot.val();
            if (text1 && text1.length) {
              this.fileName = fileName;
              this.fileType = fileType;
              this.setEditor(text1, true);
            }
            if (text2 && text2.length) {
              this.fileName2 = fileName2;
              this.fileType2 = fileType2;
              this.setEditor(text2, false);
            }
          });
      } else if (docId){
        this.docId = docId;
        const uuid = this.app.auth().currentUser.uid;
        this.app
          .database()
          .ref('/saveData/' + uuid)
          .child(docId)
          .once('value')
          .then((snapshot) => {
            this.setDiffter(snapshot.val());
        });
      }
    });

    // the editor options subscriber
    this.appSettingSubscriber = this.appService.getSettings().subscribe(appData => {
      this.options = { ...this.options, theme: appData.theme };
    });

    if (this.appService.monacoData) {
      const { data, origin, fileName, fileType } = this.appService.monacoData;
      if (origin) {
        this.fileName = fileName;
        this.fileType = fileType;
        this.firstFileMesage = '/ ' + this.fileName;
      } else {
        this.fileName2 = fileName;
        this.fileType2 = fileType;
        this.secondFileMesage = '/ ' + this.fileName2;
      }
      this.setEditor(data, origin);
      this.appService.monacoData = null;
    }
  }

  ngOnDestroy(): void {
    this.appSettingSubscriber.unsubscribe();
    this.routeSubscriber.unsubscribe();
  }

  onEdit(codeFlag: boolean){
    this.appService.monacoData = {
      data: (codeFlag) ? btoa(this.originalModel.code) : btoa(this.modifiedModel.code),
      fileName: (codeFlag) ? this.fileName : this.fileName2,
      fileType: (codeFlag) ? this.fileType : this.fileType2,
    };
    this.router.navigate(['/editor']);
  }

  onFindOrigin(){ this.editor._editor.originalEditor.trigger('', 'actions.find'); }
  onFindModified(){ this.editor._editor.modifiedEditor.trigger('', 'actions.find'); }

  onSelect(event, fileFlag){
    if (fileFlag){
      this.readFile(event.addedFiles[0], true)
        .then((f: string) => { this.setEditor(f, true); }
      ).catch( e => console.log(e));
    }else {
      this.readFile(event.addedFiles[0], false)
        .then((f: string) => { this.setEditor(f, false); }
      ).catch( e => console.log(e));
    }
  }

  private async readFile(file: File, activeEditorFlag: boolean): Promise<string | ArrayBuffer> {
    if (activeEditorFlag){
      this.fileName = file.name;
      this.fileType = file.type;
      this.firstFileMesage = '/ ' + this.fileName;
    } else {
      this.fileName2 = file.name;
      this.fileType2 = file.type;
      this.secondFileMesage = '/ ' + this.fileName2;
    }
    return new Promise<string | ArrayBuffer>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = e => {
        return resolve((e.target as FileReader).result);
      };
      reader.onerror = e => {
        console.error(`FileReader failed on file ${file.name}.`);
        return reject(null);
      };
      if (!file) {
        console.error('No file to read.');
        return reject(null);
      }
      reader.readAsDataURL(file);
    });
  }

  private base64Decoder(phrase: any): string{
    const codedText = phrase.split('base64,').pop();
    return atob(codedText);
  }

  private setEditor(t: any, flag: boolean): void{
    const text = this.base64Decoder(t);
    if (flag){
      this.originalModel = Object.assign({}, this, this.originalModel, {code: text, language: this.fileType});
    } else{
      this.modifiedModel = Object.assign({}, this, this.modifiedModel, {code: text, language: this.fileType2});
    }
  }

  private setDiffter(data: any): void{
    const text1 = this.base64Decoder(data.text1);
    const text2 = this.base64Decoder(data.text2);
    this.originalModel = Object.assign({}, this, this.originalModel, {code: text1, language: this.fileType});
    this.modifiedModel = Object.assign({}, this, this.modifiedModel, {code: text2, language: this.fileType2});
    this.firstFileMesage = data.fileName ? `/${data.fileName}` : '/Drop your file here';
    this.secondFileMesage = data.fileName2 ? `/${data.fileName2}` : '/Drop your file here';
    this.fileName = data.fileName;
    this.fileType = data.fileType;
    this.fileName2 = data.fileName2;
    this.fileType2 = data.fileType2;
  }

  onSave(flag){
    (flag) ? this.saveFile(this.originalModel.code, this.fileName) : this.saveFile(this.modifiedModel.code, this.fileName2);
  }

  private saveFile(text, filename){
    const dataStr = 'data:text/*;charset=utf-8,' + encodeURIComponent(text);
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute('href', dataStr);
    downloadAnchorNode.setAttribute('download', filename);
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

  deleteCode(flag){
    if (flag){
      this.originalModel = Object.assign({}, this, this.originalModel, {code: ' '});
      this.firstFileMesage = '/Drop your file here';
    }else{
      this.modifiedModel = Object.assign({}, this, this.modifiedModel, {code: ' '});
      this.secondFileMesage = '/Drop your file here';
    }
  }

  onGenerateLink(){
    const originText = btoa(this.originalModel.code);
    const modifText = btoa(this.modifiedModel.code);
    const uid = uuidv4();
    const link = `https://diffter.com/diff/params?id=${uid}`;
    const createdBy = this.authService.getLoggedInStatus() ? this.app.auth().currentUser.email : 'anon';

    this.app
      .database()
      .ref('/shareData/' + uid)
      .set({
        text1: `${originText}`,
        text2: `${modifText}`,
        fileName: `${this.fileName}`,
        fileName2: `${this.fileName2}`,
        fileType: `${this.fileType}`,
        fileType2: `${this.fileType2}`,
        created_at: Date.now(),
        created_by: `${createdBy}`
      }, (err) => {
        if (err) {
          this.snackBar.open(`Error! ${err.message}`, 'OK', { duration: 4000 });
        } else {
          this.dialog.open(LinkDialogComponent, {
            width: '30%',
            data: {
              sharedLink: link,
              id: uid
            }
          });
        }
      }
    );
  }

  onTerms(){ this.dialog.open(TermsComponent); }

  onAuthSave(){
    const originText = btoa(this.originalModel.code);
    const modifText = btoa(this.modifiedModel.code);
    const userId = this.app.auth().currentUser.uid;
    if (this.docId){
      this.app
        .database()
        .ref('/saveData/' + userId)
        .child(this.docId)
        .update({
          tag: 'N/A',
          text1: `${originText}`,
          text2: `${modifText}`,
          modified_at: Date.now(),
        }, (err) => { this.showMessage(err, `Success! The document has been saved.`); }
      );
    }else {
      this.app
        .database()
        .ref('/saveData/' + userId)
        .push({
          screen: '1',
          tag: 'N/A',
          text1: `${originText}`,
          text2: `${modifText}`,
          fileName: this.fileName,
          fileType: this.fileType,
          fileName2: this.fileName2,
          fileType2: this.fileType2,
          created_at: Date.now(),
          modified_at: Date.now(),
        }, (err) => { this.showMessage(err, `Success! The document has been saved.`); }
      );
    }
  }

  showMessage(err, msg){
    if (err) {
      this.snackBar.open(`Error! ${err.message}`, 'OK', { duration: 4000 });
    } else {
      this.snackBar.open(msg, 'OK', { duration: 4000 });
    }
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FirebaseApp } from '@angular/fire';
import { MatSnackBar } from '@angular/material/snack-bar';

import { TermsComponent } from '../../footer/terms/terms.component';
import { LinkDialogComponent } from '../main-diff/components/link-dialog/link-dialog.component';

import { v4 as uuidv4 } from 'uuid';
import { Subscriber } from 'rxjs';
import { AppService } from 'src/app/services/app.service';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {

  @ViewChild('monacoEditor') editor: any;
  constructor(
    public app: FirebaseApp,
    private route: ActivatedRoute,
    private router: Router,
    private appService: AppService,
    public dialog: MatDialog,
    public authService: AuthenticationService,
    private snackBar: MatSnackBar
  ) {}

  appSettingSubscriber = Subscriber.EMPTY;
  routeSubscriber = Subscriber.EMPTY;

  firstFileMesage = '/Drop your file here';
  editorOptions = {
    theme: 'vs',
    language: 'javascript',
    roundedSelection: true,
    autoIndent: true,
    minimap: { enabled: true }
  };
  fileName = null;
  fileType = null;
  code = null;
  docId = null;

  ngOnInit(): void {
    this.routeSubscriber = this.route.queryParams.subscribe(params => {
      const {id, docId} = params;
      if (id){
        this.app
          .database()
          .ref('/shareData/')
          .child(id)
          .once('value')
          .then((snapshot) => {
            const { text1, fileName, fileType } = snapshot.val();
            if (text1 && text1.length) {
              this.fileName = fileName;
              this.firstFileMesage = `/${this.fileName }`;
              this.fileType = fileType;
              this.setEditor(text1);
            }
          });
      } else if (docId) {
        this.docId = docId;
        const uuid = this.app.auth().currentUser.uid;
        this.app
          .database()
          .ref('/saveData/' + uuid)
          .child(docId)
          .once('value')
          .then((snapshot) => {
            const { text1, fileName, fileType } = snapshot.val();
            if (text1 && text1.length) {
              this.fileName = fileName;
              this.firstFileMesage = `/${this.fileName }`;
              this.fileType = fileType;
              this.setEditor(text1);
            }
        });
      }
    });

    this.appSettingSubscriber = this.appService.getSettings().subscribe(appData => {
      this.editorOptions = { ...this.editorOptions, theme: appData.theme };
    });

    if (this.appService.monacoData) {
      const { data, fileName, fileType } = this.appService.monacoData;
      this.fileName = fileName;
      this.fileType = fileType;
      this.firstFileMesage = '/ ' + this.fileName;
      this.setEditor(data);
      this.appService.monacoData = null;
    }
  }

  ngOnDestroy(): void {
    this.appSettingSubscriber.unsubscribe();
    this.routeSubscriber.unsubscribe();
  }

  onOpenInDiff(flag){
    this.appService.monacoData = {
      data: btoa(this.code),
      fileName: `${this.fileName}`,
      fileType: `${this.fileType}`,
      origin: flag
    };
    this.router.navigate(['/diff']);
  }

  onFind(){ this.editor._editor.trigger('', 'actions.find'); }

  onSave(){
    this.saveFile(this.code, this.fileName);
    this.firstFileMesage = '/Drop your file here';
  }

  private saveFile(text, filename){
    const dataStr = 'data:text/*;charset=utf-8,' + encodeURIComponent(text);
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute('href', dataStr);
    downloadAnchorNode.setAttribute('download', filename);
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

  onRemove(){
    this.code = null;
    this.firstFileMesage = '/Drop your file here';
  }

  onSelect(event){
    this.firstFileMesage = '/ ' + event.addedFiles[0].name;
    this.readFile(event.addedFiles[0])
      .then((f: string) => { this.setEditor(f); }
    ).catch( e => console.log(e));
  }

  private async readFile(file: File): Promise<string | ArrayBuffer> {
    this.fileName = file.name;
    this.fileType = file.type;
    return new Promise<string | ArrayBuffer>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = e => {
        return resolve((e.target as FileReader).result);
      };
      reader.onerror = e => {
        console.error(`FileReader failed on file ${file.name}.`);
        return reject(null);
      };
      if (!file) {
        console.error('No file to read.');
        return reject(null);
      }
      reader.readAsDataURL(file);
    });
  }

  private setEditor(t: any): void{
    this.code = this.base64Decoder(t);
    this.editorOptions = { ...this.editorOptions, language: this.fileType };
  }

  private base64Decoder(phrase: any): string{
    const codedText = phrase.split('base64,').pop();
    return atob(codedText);
  }

  onTerms(){ this.dialog.open(TermsComponent); }

  onGenerateLink(){
    const uid = uuidv4();
    const link = `https://diffter.com/editor/params?id=${uid}`;
    const t = btoa(this.code);
    const createdBy = this.authService.getLoggedInStatus() ? this.app.auth().currentUser.email : 'anon';

    this.app
      .database()
      .ref('/shareData/' + uid)
      .set({
        text1: `${t}`,
        fileName: `${this.fileName}`,
        fileType: `${this.fileType}`,
        text2: ``,
        fileName2: '',
        fileType2: '',
        created_at: Date.now(),
        created_by: `${createdBy}`
      }, (err) => {
        if (err) {
          this.snackBar.open(`Error! ${err.message}`, 'OK', { duration: 4000 });
        } else {
          this.dialog.open(LinkDialogComponent, {
            width: '30%',
            data: {
              sharedLink: link,
              id: uid
            }
          });
        }
      }
    );
  }

  onAuthSave(){
    const userId = this.app.auth().currentUser.uid;
    if (this.docId){
        this.app
          .database()
          .ref('/saveData/' + userId)
          .child(this.docId)
          .update({
            tag: 'N/A',
            text1: btoa(this.code),
            modified_at: Date.now(),
          }, (err) => {
            if (err) {
              this.snackBar.open(`Error! ${err.message}`, 'OK', { duration: 4000 });
            } else {
              this.snackBar.open(`Success! The document has been saved.`, 'OK', { duration: 4000 });
            }
          }
        );
    }else{
      this.app
        .database()
        .ref('/saveData/' + userId)
        .push({
          screen: '2',
          tag: 'N/A',
          fileName: this.fileName,
          fileType: this.fileType,
          text1: btoa(this.code),
          created_at: Date.now(),
          modified_at: Date.now(),
        }, (err) => {
          if (err) {
            this.snackBar.open(`Error! ${err.message}`, 'OK', { duration: 4000 });
          } else {
            this.snackBar.open(`Success! The document has been saved.`, 'OK', { duration: 4000 });
          }
        }
      );
    }
  }
}

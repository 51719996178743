<div class="login_module">

    <form class="login_form" *ngIf="formFlag" [formGroup]="loginForm" (ngSubmit)="submit()">
        <mat-form-field appearance="fill">
          <mat-label>Enter your email</mat-label>
          <input matInput placeholder="name@example.com" formControlName="email" name="email" required>
          <mat-error>{{getEmailErrorMessage(loginForm.value)}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Enter your password</mat-label>
            <input matInput [type]="hide ? 'password' : 'text'" formControlName="pass" name="pass" required>
            <mat-error>The password cannot be blank</mat-error>
            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
        </mat-form-field>
        <button mat-button type='button' (click)='onForgotPass()'>Forgot pasword? </button>
        <div class="form_btns">
            <button mat-raised-button (click)="formFlag = false">Sign Up</button>
            <button mat-raised-button color="primary" [disabled]="!loginForm.valid">Login</button>
        </div>
    </form>

    <form class="signup_form" *ngIf="!formFlag" [formGroup]="signupForm" (ngSubmit)="submitSignUp()">

        <mat-form-field appearance="fill">
          <mat-label>Enter your email</mat-label>
          <input matInput placeholder="name@example.com" formControlName="email" required>
          <mat-error>{{getEmailErrorMessage(signupForm.value)}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Enter your password</mat-label>
            <input matInput [type]="hide ? 'password' : 'text'" formControlName="pass" required>
            <mat-error>{{getPassErrMsg(signupForm.value)}}</mat-error>
            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Confirm your password</mat-label>
            <input matInput [type]="hide2 ? 'password' : 'text'" formControlName="pass2" required>
            <mat-error>{{getConfirmPassErrMsg(signupForm.value)}}</mat-error>
            <button mat-icon-button matSuffix (click)="hide2 = !hide2" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide2">
            <mat-icon>{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
        </mat-form-field>

        <div class="form_btns">
            <button mat-raised-button (click)="formFlag = true">Back to Login</button>
            <button mat-raised-button color="primary" [disabled]="!signupForm.valid">Create Account</button>
        </div>
    </form>


</div>



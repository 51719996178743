import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-msg',
  templateUrl: './confirm-msg.component.html',
  styleUrls: ['./confirm-msg.component.scss']
})
export class ConfirmMsgComponent {

  constructor(
    public dialogRef: MatDialogRef<ConfirmMsgComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  onConfirm(){
    this.dialogRef.close({result: true});
  }
}

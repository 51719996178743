import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class AppService {

    private settingChange: BehaviorSubject<any>;
    public monacoData: any;

    constructor() {
        this.settingChange = new BehaviorSubject<any>({
            theme: 'vs'
        });
        this.monacoData = null;
    }

    // settings
    public getSettings(): Observable<any> { return this.settingChange.asObservable(); }
    public setSettings(value: object): void { this.settingChange.next(value); }
    public setSettingsValue(key: any, value: any) {
        this.settingChange.value[key] = value;
        this.settingChange.next(this.settingChange.value);
    }
}

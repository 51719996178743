import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  userData$: Observable<any>;
  private isLoggedIn: boolean;

  constructor(private angularFireAuth: AngularFireAuth) {
    this.userData$ = angularFireAuth.authState;
    this.isLoggedIn = false;
  }

  /* Sign up */
  SignUp(email: string, password: string) {
    return this.angularFireAuth
      .createUserWithEmailAndPassword(email, password)
      .then(res => {
        this.isLoggedIn = true;
        return {
          result: true,
          msg: 'Successfully signed up!',
          res,
          err: null
        };
      })
      .catch(err => {
        this.isLoggedIn = false;
        return {
          result: false,
          msg: 'Something is wrong:',
          err
        };
      });
  }

  /* Sign in */
  SignIn(email: string, password: string) {
    this.isLoggedIn = true;
    return this.angularFireAuth
      .signInWithEmailAndPassword(email, password)
      .then(res => {
        return {
          result: true,
          msg: 'Successfully signed in',
          res,
        };
      })
      .catch(err => {
        this.isLoggedIn = false;
        return {
          result: false,
          msg: err.message
        };
      });
  }

  /* Forgot Password */
  ForgotPass(email: string) {
    this.angularFireAuth.sendPasswordResetEmail(email);
  }

  // /* Change password */
  // ChangePass(pass: string) {

  // }

  /* Sign out */
  SignOut() {
    this.isLoggedIn = false;
    this.angularFireAuth.signOut();
  }

  getLoggedInStatus() { return this.isLoggedIn; }
}

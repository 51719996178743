<footer>
    <ul>
        <li>@ Diffter</li>
        <li>|</li>
        <li>All rights reserved</li>
        <li>|</li>
        <li class="pointer" (click)='onTerms()'>Terms</li>
        <li>|</li>
        <a href = "mailto: diffterhelp@gmail.com">Contact Us</a>
    </ul>
</footer>
<div class='homeBlock'>
  <div class="titleBlock">
    <h1>Diffter</h1>
    <h2>The simple way to compare two files online</h2>
    <div class="mainSelect">
      <div [routerLink]="['/diff/']"> 
        <h2>Text Compare </h2>
        <img src="assets/pic/main/comparetext.png" alt="compare text online">
      </div>
      <div [routerLink]="['/editor/']"> 
        <h2>Code Editor </h2>
        <img src="assets/pic/main/codeeditor.png" alt="code editor online">
      </div>
    </div>
  </div>

  <div class="syntaxBlock">
    The supported syntax: JavaScript, TypeScript, Python, PHP, C#, C++, Razor, Markdown, Java, VB, Diff, and more...
  </div>

  <div class="adsMainPage">
    <iframe 
      src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=48&l=ez&f=ifr&linkID=500944cade392a1d97b2aa6e7f1f30e0&t=34710-20&tracking_id=34710-20" 
      width="728" 
      height="90" 
      scrolling="no" 
      border="0" 
      marginwidth="0" 
      style="border:none;" 
      frameborder="0">
    </iframe>
  </div>
</div>
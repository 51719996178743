<div class="main_editor_body" [ngClass]="editorOptions.theme">
    <div class="tool_bar">
        <div class="file_field" ngx-dropzone [multiple]="false" [accept]="'text/*'" (change)="onSelect($event)">
            <button mat-icon-button>
                <mat-icon>folder_open</mat-icon>
            </button>
            <p>{{ firstFileMesage }}</p>
        </div>
        <button mat-icon-button *ngIf="code?.length > 1 && this.authService.getLoggedInStatus()" (click)="onAuthSave()" matTooltip="save document"><mat-icon>save</mat-icon></button>
        <button mat-icon-button *ngIf="code?.length > 1" (click)="onSave()" matTooltip="download document"> <mat-icon>archive</mat-icon> </button>
        <button mat-icon-button *ngIf="code?.length > 1" (click)="onFind()" matTooltip="find in document"> <mat-icon>find_in_page</mat-icon> </button>
        <button mat-icon-button *ngIf="code?.length > 1" (click)="onOpenInDiff(true)" matTooltip="open documents in origin"><mat-icon>flip</mat-icon></button>
        <button mat-icon-button *ngIf="code?.length > 1" (click)="onOpenInDiff(false)" matTooltip="open documents in modified"><mat-icon class="flip180">flip</mat-icon></button>
        <button mat-icon-button *ngIf="code?.length > 1" matTooltip="share documents" [matMenuTriggerFor]="shareMenu"><mat-icon>share</mat-icon></button>
        
        <button mat-icon-button *ngIf="code?.length > 1" (click)="onRemove();" matTooltip="remove document"> <mat-icon>delete_sweep</mat-icon> </button>

        <mat-menu #shareMenu="matMenu">
            <p class="menu_label" (click)='onTerms()'>Attention. By clicking Generate link, you agree to our terms.</p>
            <button class="menu_button" mat-menu-item (click)="onGenerateLink()">Generate link</button>
        </mat-menu>
    </div>
    <div class="editor_panel">
        <ngx-monaco-editor [options]="editorOptions" class="editor" #monacoEditor [(ngModel)]="code"></ngx-monaco-editor>
        <div class="info_panel"> 
            <iframe 
                src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=14&l=ez&f=ifr&linkID=8f24a9ca810ee529f2425f4f5a04a4ce&t=34710-20&tracking_id=34710-20" 
                width="160" 
                height="600" 
                scrolling="no" 
                border="0" 
                marginwidth="0" 
                style="border:none;" 
                frameborder="0">
            </iframe>
        </div>      
    </div>
</div>

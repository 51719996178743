import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';

// Materials and icons
import { MaterialModule } from './material-modules';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// Services
import { AppService } from './services/app.service';
import { AuthenticationService } from './services/authentication.service';

// Components
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { TermsComponent } from './components/footer/terms/terms.component';
import { LoginComponent } from './components/login/login.component';
import { EditComponent } from './components/context/edit/edit.component';
import { MainDiffComponent } from './components/context/main-diff/main-diff.component';
import { LinkDialogComponent } from './components/context/main-diff/components/link-dialog/link-dialog.component';
import { MainComponent } from './components/context/main/main.component';
import { NotFoundComponent } from './components/messages/not-found/not-found.component';
import { ErrorMsgComponent } from './components/messages/error-msg/error-msg.component';
import { ConfirmMsgComponent } from './components/messages/confirm-msg/confirm-msg.component';
import { InfoMsgComponent } from './components/messages/info-msg/info-msg.component';

import { MonacoEditorModule } from 'ngx-monaco-editor';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgSocialLinksModule } from 'ng-social-links';
import { AdsenseModule } from 'ng2-adsense';

// Firebase and Env
import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { environment } from 'src/environments/environment';
import { ForgotPassDialogComponent } from './components/login/forgot-pass-dialog/forgot-pass-dialog.component';
import { CommonModule, DatePipe } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    MainDiffComponent,
    MainComponent,
    NotFoundComponent,
    TermsComponent,
    ErrorMsgComponent,
    LinkDialogComponent,
    ConfirmMsgComponent,
    EditComponent,
    LoginComponent,
    InfoMsgComponent,
    ForgotPassDialogComponent
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireMessagingModule,
    AngularFireDatabaseModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDropzoneModule,
    MaterialModule,
    MonacoEditorModule.forRoot(),
    NgSocialLinksModule.forRoot(),
    AdsenseModule.forRoot({
      adClient: environment.adsense.adClient
    }),
  ],
  providers: [
    AppService,
    AuthenticationService,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
